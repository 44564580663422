<div class="modal-header p-0">
    <button class="button-close base-btn mb-2" mat-button [mat-dialog-close]="false">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"/>
        </svg>
    </button>
</div>
<div class="modal-body p-0">
    <section class="sec">
        <div class="d-flex justify-content-center">
            <div class="">
                <!-- form card login -->
                <div class="card-header login-header">
                    <h3 class="font-semi-bold mb-2 text-center">Terms And Services</h3>
                    <!-- <p class="font-regular">Enter your detail below.</p> -->
                </div>
                <div class="card-body pt-0">
                    <form class="form" [formGroup]="completeProfileFrom" (ngSubmit)="onSubmit()">
                        <!-- <div class="form-group mb-2">
                          <label class="font-medium py-2" for="firstName">First Name</label>
                          <input type="text" id="firstName" formControlName="firstName" placeholder="First Name" class="form-control font-medium input-field" [ngClass]="{ 'is-invalid':   completeProfileFrom.get('customer').errors}">
                          <div *ngIf="completeProfileFrom.get('firstName')?.invalid && completeProfileFrom.get('firstName')?.touched">
                            <small class="text-danger">First name is required.</small>
                          </div>
                        </div>
                      
                        <div class="form-group mb-2">
                          <label for="lastName">Last Name</label>
                          <input type="text" id="lastName" formControlName="lastName" placeholder="Last Name" class="form-control">
                          <div *ngIf="completeProfileFrom.get('lastName')?.invalid && completeProfileFrom.get('lastName')?.touched">
                            <small class="text-danger">Last name is required.</small>
                          </div>
                        </div> -->
                      
                        <div class="form-group mb-2">
                          <label class="font-medium py-2" for="email">Email *</label>
                          <input type="email" id="email" formControlName="email" placeholder="Email" class="form-control font-medium input-field">
                          <div *ngIf="submitted && f['email'].errors">
                            <small class="text-danger">Enter a valid email address.</small>
                          </div>
                        </div>
                        
                        <div class="form-group mb-2">
                          <label class="font-medium py-2" for="phoneNumber">Phone Number *</label>
                          <input type="phoneNumber" id="phoneNumber" formControlName="phoneNumber" placeholder="phoneNumber" class="form-control font-medium input-field">
                          <div *ngIf="submitted && f['phoneNumber'].errors">
                            <small class="text-danger">Phone Number is required.</small>
                          </div>
                        </div>
                      
                        <!-- <div class="form-group mb-2">
                          <label class="font-medium py-2" for="phone">Phone *</label>
                          <input type="tel" id="phone" formControlName="phone" placeholder="Phone" class="form-control font-medium input-field">
                          <div *ngIf="completeProfileFrom.get('phone')?.invalid && completeProfileFrom.get('phone')?.touched">
                            <small class="text-danger">Phone number is required.</small>
                          </div>
                        </div> -->
                      
                        <div class="custom-control custom-checkbox d-flex align-items-start mt-2">
                          <input type="checkbox" class="custom-control-input mt-1" id="terms" formControlName="terms">
                          <label for="terms" class="form-check-label mx-2">
                            I agree to receive service updates from {{ clientCompany }} at the phone number provided above. I understand that these messages may include reminders, technician arrival notifications, job status updates, and payment confirmations. Msg & data rates may apply. Reply STOP to unsubscribe at any time.
                            <!-- I agree to <a href="https://servicegalaxy.com/terms-of-service/#sms" target="_blank">terms and conditions</a> provided by the company. By providing my phone number, I agree to receive text messages from the business. -->
                          </label>
                        </div>
                        <div class="d-flex justify-content-center align-items-center" *ngIf=" submitted && f['terms']?.errors">
                          <small class="text-danger">You must accept the terms and conditions.</small>
                        </div>
                      
                        <div class="d-flex justify-content-center mt-3">
                          <label for=""><a href="https://www.servicegalaxy.com/terms-of-service/" target="_blank" rel="noopener noreferrer">Terms Of Service</a> | <a href="https://www.servicegalaxy.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a> </label>
                        </div>

                        <!-- <div class="form-group mt-3">
                            <button type="submit" class="form-control theme-button base-btn" id="btnLogin">
                                <span>Continue</span>
                            </button>
                        </div> -->
                      </form>
                      
                </div>
            </div>
        </div>
    </section>
   
</div>
<div class="modal-footer">
    <button type="button" class="btn base-btn cancel" [mat-dialog-close]="false">{{data?.sayNo}}</button>
    <button type="button" class="btn base-btn submit" (click)="close()">{{data?.sayYes}}</button>
</div>
