import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { API_ROUTES } from 'src/app/core/_constants/api-route.constant';
import { RouteConstant } from 'src/app/core/_constants/route.constant';
import { CommonService } from 'src/app/core/_services/common.service';
import { MainService } from 'src/app/core/_services/main.service';
import { LocalStorageProvider } from 'src/app/core/_services/storage.service';

@Component({
  selector: 'app-service-providers',
  templateUrl: './service-providers.component.html',
  styleUrls: ['./service-providers.component.scss']
})
export class ServiceProvidersComponent implements OnInit {
  serviceProviders: any;
  serviceProviderForm: FormGroup
  serviceProvider: any;
  search: any;
  totalCount = 0;
  constructor(public dialogRef: MatDialogRef<ServiceProvidersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private loader: NgxUiLoaderService,
    private toastr: ToastrService,
    private router: Router,
    private service: MainService,
    private storageService: LocalStorageProvider,
    private commonService: CommonService,
    private fb: FormBuilder) {
    this.serviceProviderForm = this.createForm();
  }

  ngOnInit(): void {
    this.getServiceProviderList();
  }
  createForm() {
    return this.fb.group({
      serviceProvider: [this.data.provider === '' ? this.storageService.getItem('ServiceProvider').companyId : this.data.provider]
    })
  }
  getServiceProviderList() {
    let data = {
      search: this.search
    }
    this.service.get(data, `${API_ROUTES.ACCOUNT.serviceProviderList}`).pipe().subscribe(response => {
      if (response.status) {
        this.serviceProviders = response.data?.company_data;
        this.totalCount = this.serviceProviders.length;
      } else {
        this.totalCount = 0;
        // this.dialogRef.close(false);
        // this.toastr.error(response.msg);
      }
    })
  }

  submit(post: any) {
    if (post.serviceProvider != '') {
      if (post.serviceProvider !== this.data.provider && post.serviceProvider !== this.storageService.getItem('ServiceProvider').clientId) {
        const selectedServiceProvider = this.serviceProviders[this.serviceProviders.map((object: any) => object._id).indexOf(post.serviceProvider)];
        let data = {
          companyId: post.serviceProvider
        }
        this.loader.start();
        this.service.post(data, `${API_ROUTES.ACCOUNT.changeProvider}`).pipe().subscribe(response => {
          if (response.status) {
            this.storageService.setItem('token', response.data.token);
            this.getProfileDetails(response.data);
            this.router.navigate([`${RouteConstant.Dashboard}`]);
            this.dialogRef.close(true);
          } else {
            this.toastr.error(response.msg);
          }
          this.loader.stop();
        })
      } else {
        this.dialogRef.close(true);
      }
    }
  }


  currentUser:any;
  getProfileDetails(data:any) {
    this.loader.start();
    this.service.get({}, `${API_ROUTES.ACCOUNT.viewProfile}`).pipe().subscribe(response => {
      this.loader.stop();
      if (response.status) {
        this.currentUser = response.data;
        this.commonService.setserviceProviderData(data, this.currentUser);
      }
    })
  }

  cancel(data: any) {
    this.dialogRef.close(false);
  }
  searchServiceProvider(value: any) {
    this.search = value;
    this.getServiceProviderList();
  }
  checkButton(id: any) {
    this.serviceProviderForm.controls['serviceProvider'].setValue(id);
  }

}
