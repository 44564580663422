import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { API_ROUTES } from 'src/app/core/_constants/api-route.constant';
import { AppConst } from 'src/app/core/_constants/app.constant';
import { RouteConstant } from 'src/app/core/_constants/route.constant';
import { CommonService } from 'src/app/core/_services/common.service';
import { MainService } from 'src/app/core/_services/main.service';
import { LocalStorageProvider } from 'src/app/core/_services/storage.service';
import { ChoosePaymentComponentComponent } from '../../dialogs/choose-payment-component/choose-payment-component.component';
import { MatDialog } from '@angular/material/dialog';
import { PaymentsComponent } from '../../dialogs/payments/payments.component';
import { PaymentStyleComponent } from '../../dialogs/payment/payment.component';

@Component({
  selector: 'app-work-order',
  templateUrl: './work-order.component.html',
  styleUrls: ['./work-order.component.scss']
})
export class WorkOrderComponent implements OnInit {
  dateFormat = AppConst.DATE_FORMAT;
  @Input() preview: any;
  @Input() orders: any;
  @Input() review: any;
  @Input() page: any;
  @Input() hidePrice: any;
  @Input() totalAmount: any;
  @Input() details: any;
  @Output() changesHappend = new EventEmitter<boolean>();

  fileUrl: any;
  fileBuffer: any;
  id: string;
  taxAmount: any;
  totalAmountPaid: any = 0;
  constructor(private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loader: NgxUiLoaderService,
    private toastr: ToastrService,
    private storageService: LocalStorageProvider,
    private service: MainService,
    private dialog: MatDialog
  ) {

  }
  ngOnChanges(change: SimpleChange): void {
    // if(change['orders']?.currentValue){
    //   this.totalAmount = this.calculateAmount('total');
    // }
    console.log(this.details, 'this is details')

    if (this.orders && this.orders.length) {
      this.orders.forEach(element => {
        element['total'] = (+element['price'] * (+element['quantity'])) + Number(element['tax'])
      });
    }
    this.totalTax(this.orders)
    this.totalAmount = this.calculateAmount('total')
    this.taxAmount = this.calculateAmount('tax')
  }

  ngOnInit(): void {
    console.log(this.details, 'this is details')
    this.getParams();

  }
  calculateAmount(key: any) {
    let total = 0.00;
    if (this.orders) {
      this.orders.map((order: any) => {

        total = total + parseFloat(order[key]);
      });
      return total.toFixed(2);
    }
    return 0.00;
  }


  getParams() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params) {
        this.id = params['oppId'] ? params['oppId'] : params['jobId'];
      }
    })
  }

  showImages(images: any) {
    this.commonService.openImageSliderModal(images)
  }

  reviewWorkOrder() {
    this.storageService.setItem('Orders', this.orders)
    this.router.navigate([`${RouteConstant.WorkOrderDetail}`], { queryParamsHandling: "merge", relativeTo: this.activatedRoute })
  }

  viewOrder(order: any) {
    this.commonService.setActiveEstimate(order);
  }
  setStatusStyle(status: any) {
    switch (status) {
      case 'Approved':
        return 'green-btn';
      case 'Completed':
        return 'blue-btn';
      case 'Not_Started':
        return 'grey-btn';
      case 'Started':
        return 'light-green-btn';
      case 'Paused':
        return 'orange-btn';
      default:
        return 'red-btn';
    }
  }

  callApi() {
    if (this.fileBuffer) {
      this.download();
      return;
    }
    this.loader.start();
    const data = {
      oppId: this.id,
      previewType: 'workOrder'
    }
    this.service.get(data, `${API_ROUTES.OPPORTUNITY.previewWorkOrder}`).subscribe({
      next: (response: any) => {
        if (response.status) {
          this.fileBuffer = response.data;
          const uint8Array = new Uint8Array(this.fileBuffer.data);
          const fileData = new Blob([uint8Array], { type: 'application/pdf' });
          this.fileUrl = window.URL.createObjectURL(fileData);
          this.download();
        }
      }, error: (err) => {
        this.loader.stop();
        this.toastr.error(err.msg)
      },
      complete: () => this.loader.stop()
    })
  }

  download() {
    // Create a link element for downloading the file
    const downloadLink: any = document.createElement('a');
    downloadLink.href = this.fileUrl;
    downloadLink.download = 'work_order.pdf';
    // Trigger a click event on the link to download the file
    downloadLink.click();
    this.loader.stop();
  }

  formatText(val, precision) {
    console.log(val)
    if (val) {
      return Number(val).toFixed(precision)
    }
    return 0.00
  }
  totalTaxAmount: any;
  totalTax(order) {

    if(this.details?.work_order_data){
      this.totalTaxAmount = this.details?.work_order_data.reduce((acc, item) => {
        const taxValue = parseFloat(item.tax);
        return acc + taxValue;
      }, 0);
    }
    console.log(this.totalTaxAmount)

  }
  openPaymentPopUp() {
    if (this.details?.totalAmount - Number(this.details?.totalAmountPaid) <= 0) {
      this.toastr.error('You have already reached the payment limit.')
    }
    else {
      const data = {
        totalAmount: this.totalAmount,
        balanceRemaining: this.details?.totalAmount - Number(this.details?.totalAmountPaid),
        payment: Number(this.totalAmountPaid),
        oppId: this.id,
        operDetails: this.details
      }

      const dialogRef = this.dialog.open(PaymentStyleComponent, {
        maxHeight: '100vh',
        width: '700px',
        maxWidth: '100%',
        disableClose: true,
        data: {
          type: 2,
          data: data
        }
      })
      dialogRef.afterClosed().subscribe((confirm: any) => {
        console.log(confirm, '1')
        if (confirm !== undefined && confirm !== null && confirm) {
          this.getJobDetails(this.id)

        }
      });
    }
  }
  getJobDetails(id: any) {
    this.changesHappend.emit(true);
    // const data = {
    //   oppId: id
    // }
    // this.loader.start();
    // this.service.get(data, `${API_ROUTES.OPPORTUNITY.getOpportunityDetails}`).pipe().subscribe(response => {
    //   if (response.status) {
    //     this.details = response?.data[0];
    //     this.loader.stop()

    //   } else {
    //     this.toastr.error(response.msg);
    //   }
    //   this.loader.stop();

    // })
  }

}
