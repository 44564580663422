import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/views/partials/dialogs/confirm-dialog/confirm-dialog.component';
import { APP_CONSTANT } from '../_constants/app.constant';
import { AuthService } from './auth.service';
import { Location } from '@angular/common'
import { CollectSignatureComponent } from 'src/app/views/partials/dialogs/collect-signature/collect-signature.component';
import { LocalStorageProvider } from './storage.service';
import { ImageSliderComponent } from 'src/app/views/partials/dialogs/image-slider/image-slider.component';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  // title = new BehaviorSubject('');
  private serviceProviderData = new Subject<any>();
  public serviceProviderData$ = this.serviceProviderData.asObservable();
  dialogRef:any;
  private reset = new Subject<any>();
  private activeEstimate = new Subject<any>();
  public activeEstimate$ = this.activeEstimate.asObservable();

  private currentUser = new Subject<any>();
  public currentUser$ = this.currentUser.asObservable();
  
  constructor(private dialog: MatDialog,private storageService: LocalStorageProvider,private authService:AuthService,private location: Location) { }

  // setTitle(title: string) {
  //   this.title.next(title);
  // }
  sendResetClickEvent() {
    this.reset.next;
  }
  setActiveEstimate(data:any){
    this.activeEstimate.next(data);
  }
  getResetClickEvent(): Observable<any>{ 
      return this.reset.asObservable();
  }
  setserviceProviderData(data:any, user?:any){
    const currentUser = this.storageService.getItem('CurrentUser');
    this.storageService.setItem('ServiceProvider',data);
    if(data?.companyId !== currentUser.selectedServiceProvider.companyId){
      currentUser.selectedServiceProvider = data;
      if(user){
        this.storageService.setItem('CurrentUser',user);
        this.setCurrentUser(user);
      } else {
        this.storageService.setItem('CurrentUser',currentUser);
        this.setCurrentUser(currentUser);
      }
    }
    this.serviceProviderData.next(data);
  }

  setCurrentUser(data:any){
    this.currentUser.next(data);
  }
  openLogoutDialog(){
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxHeight: '100vh',
      width:'500px',
      data: {
        title: `${APP_CONSTANT.LOGOUT.title}`,
        sayYes: `${APP_CONSTANT.LOGOUT.logout}`,
        sayNo: `${APP_CONSTANT.LOGOUT.cancel}`
      }
    });
    dialogRef.afterClosed().subscribe((confirm: any) => {
      if (confirm !== undefined && confirm !== null && confirm) {
        this.authService.logout();
      }
    });
  }
  goBack(){
    this.location.back();
  }

  collectSignature(){
    this.dialogRef = this.dialog.open(CollectSignatureComponent, {
      panelClass: 'collect-sign-dialog',
      // maxHeight: '90vh',
     // width:'700px',
      data: {}
    });
  }
  public confirmed() : Observable<any> {
    return this.dialogRef.afterClosed()
  }

  openImageSliderModal(images:any){
    const dialogRef = this.dialog.open(ImageSliderComponent, {
      minWidth: '100%',
      height: '100vh',
      backdropClass: 'backdropBackground',
      panelClass: 'imageSlider',
      data: {
        images: images
      }
    });
  }
  messageCount=new Subject<any>();
  sendMessage(msg:any) {
    this.messageCount.next(msg);
    }
    
   getMessage(): Observable<any>{ 
     return this.messageCount.asObservable();
   }
  
  

  
}
