import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppConst } from 'src/app/core/_constants/app.constant';
import { PaymentsComponent } from '../../dialogs/payments/payments.component';
import { ChoosePaymentComponentComponent } from '../../dialogs/choose-payment-component/choose-payment-component.component';
import { PaymentStyleComponent } from '../../dialogs/payment/payment.component';


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  public dateFormat = AppConst.DATE_FORMAT;
  @Input() totalAmount: number = 0;
  @Input() totalAmountPaid: number = 0;
  @Input() oppDetails: any = [];
  @Input() preview: any;
  @Input() payments: any | [];
  @Input() openPopup: any | [];
  @Input() serviceData: any | [];
  @Output() getDetailss = new EventEmitter<boolean>();
  serviceName: any;
  balanceRemaining: number;
  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (Array.isArray(this.serviceData)) {
      this.serviceName = this.serviceData.map(item => item.itemName).join(', ');
    } else {
      this.serviceName = this.serviceData?.itemName;
    }
    if (changes && changes['totalAmount'] && changes['totalAmount'].currentValue) {
      this.balanceRemaining = this.totalAmount - this.totalAmountPaid;
    }
    if (changes && changes['payments'] && changes['payments'].currentValue.length) {
      this.payments = changes['payments'].currentValue;
    }
    if (changes && changes['openPopup'] && changes['openPopup'].currentValue) {
      console.log(this.openPopup)
      return;
      if (this.openPopup == 'true') {
        this.openPaymentPopUp()
      }
    }
  }

  formatValue(val: any) {
    if (val) {
      val = Number(val).toFixed(2)
      return '$' + val;
    }
    else {
      return '$0'
    }
  }

  openPaymentPopUp() {
    console.log('------')
    const data = {
      totalAmount: this.totalAmount,
      balanceRemaining: this.oppDetails?.totalAmount - Number(this.oppDetails?.totalAmountPaid),
      payment: Number(this.totalAmountPaid),
      oppId: this.oppDetails._id,
      operDetails: this.oppDetails
    }
    const dialogRef = this.dialog.open(PaymentStyleComponent, {
      maxHeight: '100vh',
      width: '700px',
      maxWidth: '100%',
      disableClose: true,
      data: {
        type: 2,
        data: data
      }
    })
    dialogRef.afterClosed().subscribe((confirm: any) => {
      if (confirm !== undefined && confirm !== null && confirm) {
        let flag = true;
        this.getDetailss.emit(flag);
      }
    });
  }

  getStatus(payment) {
    if (payment.isSettled === '0') {
      return 'Approved'
    } else if (payment.isSettled === '1') {
      return 'Settled'
    } else {
      return '---';
    }
  }

}
