import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators } from 'ngx-editor';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { API_ROUTES } from 'src/app/core/_constants/api-route.constant';
import { MainService } from 'src/app/core/_services/main.service';
import { LocalStorageProvider } from 'src/app/core/_services/storage.service';

@Component({
  selector: 'app-complete-profile',
  templateUrl: './complete-profile.component.html',
  styleUrls: ['./complete-profile.component.scss']
})
export class CompleteProfileComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CompleteProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private activatedRoute: ActivatedRoute, private storageService: LocalStorageProvider, private fb: FormBuilder, private httpClient: MainService, private loader: NgxUiLoaderService, private toastr: ToastrService,
    private route: Router
  ) { }

  completeProfileFrom: FormGroup;
  emailValue:string;
  clientCompany:string= '';

  ngOnInit(): void {
    this.createForm();
    this.patchDetails();
  }

  patchDetails(){
    this.completeProfileFrom.patchValue({
      email: this.data.email,
      phoneNumber: this.data.phoneNumber
    })
    this.completeProfileFrom.get('phoneNumber')?.disable();
    this.completeProfileFrom.get('email')?.disable();
  }

  ngAfterViewInit(): void {
  }

  createForm() {
    this.completeProfileFrom = this.fb.group({
      email: ['', [Validators.required]],
      phoneNumber: ['', Validators.required],
      terms: [false]
    })
  }

  get f() {
    return this.completeProfileFrom.controls;
  }

  close(){
    this.dialogRef.close(this.completeProfileFrom.value)
  }

  submitted: boolean = false;
  onSubmit() {
    this.submitted = true;
    if (this.completeProfileFrom.valid) {
      this.loader.start();
      const payload = {
        email: this.emailValue
      }
      if(!this.completeProfileFrom.value.terms){
        this.loader.stop();
        this.route.navigate([''], { replaceUrl: true });
      } else {
        this.httpClient.patch(`${API_ROUTES.ACCOUNT.updateTC}`, payload).subscribe(res => {
          this.loader.stop();
          if (res.status) {
            this.toastr.success(res.msg);
            setTimeout(() => {
              this.route.navigate([''], { replaceUrl: true });
            }, 500)
            this.dialogRef.close();
          } else {
            this.toastr.success(res.msg);
            this.dialogRef.close();
          }
        })
      }
    }
  }
}
