import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbComponent } from './templates/breadcrumb/breadcrumb.component';
import { NgbCarouselModule, NgbModalModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { FilterDataComponent } from './templates/filter-data/filter-data.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { MatIcon, MatIconModule } from '@angular/material/icon'
//  import { NgxEditorModule } from 'ngx-editor';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { MatChipsModule, } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { CollectSignatureComponent } from './dialogs/collect-signature/collect-signature.component';
import { WorkOrderComponent } from './modules/work-order/work-order.component';
import { EstimateComponent } from './modules/estimate/estimate.component';
import { JoinArrayPipe } from 'src/app/core/_pipes/join.pipe';
import { PaginationComponent } from './templates/pagination/pagination.component';
import { SubBreadcrumbComponent } from './templates/sub-breadcrumb/sub-breadcrumb.component';
import { ScheduleComponent } from './modules/schedule/schedule.component';
import { PaymentComponent } from './modules/payment/payment.component';
import { ServiceProvidersComponent } from './dialogs/service-providers/service-providers.component';
import { ImageSliderComponent } from './dialogs/image-slider/image-slider.component';
import { ReviewDetailCarouselComponent } from './modules/review-detail-carousel/review-detail-carousel.component';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { RouterModule } from '@angular/router';
import { NgxSignaturePadModule } from '@mr-samani/ngx-signature-pad';
import { PaymentsComponent } from './dialogs/payments/payments.component';
import { OnlinePaymentsComponent } from './dialogs/online-payments/online-payments.component';
import { MatSelectModule } from '@angular/material/select';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { NoteTableComponent } from './modules/note-table/note-table.component';
import { FileTableComponent } from './modules/file-table/file-table.component';
import { MatMenuModule } from '@angular/material/menu';
import { PaymentOppSelectComponent } from './dialogs/payment-opp-select/payment-opp-select.component';
import { ChoosePaymentComponentComponent } from './dialogs/choose-payment-component/choose-payment-component.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ChequePaymentComponent } from './dialogs/cheque-payment/cheque-payment.component';
import { OnlinePaymentComponent } from './dialogs/online-payment/online-payment.component';
import { RefundDialogComponent } from './dialogs/refund-dialog/refund-dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PaymentStyleComponent } from './dialogs/payment/payment.component';
import { MatNativeDateModule } from '@angular/material/core';
import { CompleteProfileComponent } from './dialogs/complete-profile/complete-profile.component';

@NgModule({
  declarations: [
    BreadcrumbComponent,
    FilterDataComponent,
    ConfirmDialogComponent,
    CollectSignatureComponent,
    WorkOrderComponent,
    EstimateComponent,
    JoinArrayPipe,
    PaginationComponent,
    SubBreadcrumbComponent,
    ScheduleComponent,
    PaymentComponent,
    ServiceProvidersComponent,
    ImageSliderComponent,
    ReviewDetailCarouselComponent,
    PaymentsComponent,
    OnlinePaymentsComponent,
    NoteTableComponent,
    FileTableComponent,
    PaymentOppSelectComponent,
    ChoosePaymentComponentComponent,
    ChequePaymentComponent,
    OnlinePaymentComponent,
    RefundDialogComponent ,
    PaymentStyleComponent,
    CompleteProfileComponent
    
  ],
  imports: [
    CommonModule,
    NgbModalModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NgxUiLoaderModule,
    NgbCarouselModule,
    CreditCardDirectivesModule,
    MatDatepickerModule,
    NgbTimepickerModule,
    // Material Module
    MatDialogModule,
    MatMenuModule,
    MatIconModule,
    MatSelectModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatIconModule,
    RouterModule,
    NgxGalleryModule,
    NgxSignaturePadModule,
    MatNativeDateModule,
    
    AngularSvgIconModule.forRoot()
  ],
  exports: [
    WorkOrderComponent,BreadcrumbComponent,FilterDataComponent,EstimateComponent,FileTableComponent,NoteTableComponent ,NgxPaginationModule, MatDialogModule, MatIconModule,MatIcon, MatChipsModule,MatAutocompleteModule,JoinArrayPipe,PaginationComponent,SubBreadcrumbComponent,PaymentComponent,ScheduleComponent,ServiceProvidersComponent,ImageSliderComponent,ReviewDetailCarouselComponent, NgxGalleryModule,NgxSignaturePadModule],
  entryComponents:[
    ConfirmDialogComponent,BreadcrumbComponent,NoteTableComponent,FileTableComponent,WorkOrderComponent,EstimateComponent,PaginationComponent
  ]
})
export class PartialsModule { }
