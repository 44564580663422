<div class="opportunity pt-0">
    <div id="work-order" class="d-flex align-items-center justify-content-between">
        <h3 class="heading mb-0 mt-2">Work Order</h3>
        <div *ngIf="preview"  class="d-flex align-items-center">
            <button *ngIf="!review" class="btn base-btn estimate-btn" [disabled]="!(orders && orders.length)" (click)="reviewWorkOrder()">Review Work Order</button>
            <button *ngIf="review" class="btn base-btn estimate-btn" (click)="callApi()">Download</button>
        </div>
    </div>
    <div  id="work-order-list" class="opportunity-list">
        <div  *ngIf="orders && orders.length; else noDataFound" class="table-responsive scroll-hide">
            <table class="table">
                <thead class="p-2">
                    <tr>
                        <th scope="col" style="width: 48px;">Item</th>
                        <th scope="col" style="width: 58px;">Image</th>
                        <th scope="col">Item Name</th>
                        <th scope="col">Description</th>
                        <th scope="col">Video</th>
                        <th scope="col">Charge Type</th>
                        <th scope="col" *ngIf="hidePrice">Price</th>
                        <th scope="col" *ngIf="hidePrice">Tax</th>
                        <th scope="col" *ngIf="hidePrice">Qty</th>
                        <th scope="col" *ngIf="hidePrice">Total</th>
                        <th scope="col">Complete</th>
                        <th scope="col">Completed By</th>
                        <th scope="col">Date</th>
                        <th scope="col">Status</th>
                        <!-- <th scope="col">Completed</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let order of orders;let i=index" (click)="viewOrder(order)">
                        <td class="srno" >{{i+1}}</td>
                        <td (click)="order.images.length && showImages(order.itemImage)">
                            <div *ngIf="order.images.length >1 ; else showSingleImage" class="more-images ">
                            <span class="rounded-circle">{{'+'+ (order.images.length-1)}}</span>
                            <div class="image">
                                <img [src]="order.images[0].link" width="28px" height="28px" class="rounded-circle">
                            </div>
                        </div>
                        <ng-template #showSingleImage >
                            <img *ngIf="order.images.length else noMedia" [src]="order.images[0].link" width="28px" height="28px" class="rounded-circle">
                            <ng-template #noMedia>
                                <div>---</div>
                            </ng-template>
                        </ng-template></td>
                        <td >{{order['itemName']}}</td>
                        <td class="description">
                            <div>{{order['description']||'---'}}</div>
                        </td>
                        <td (click)="order.videos.length && showImages(order.itemImage)">
                            <div *ngIf="order.videos.length >1 ; else showSingleVideo" class="more-images ">
                            <span class="rounded-circle">{{'+'+ (order.videos.length-1)}}</span>
                            <div class="image">
                                <video [src]="order.videos[0].link" width="28px" height="28px" class="rounded-circle"></video>
                            </div>
                        </div>
                        <ng-template #showSingleVideo>
                            <video *ngIf="order.videos.length else noMedia" [src]="order.videos[0].link" width="28px" height="28px" class="rounded-circle"></video>
                            <ng-template #noMedia>
                                <div>---</div>
                            </ng-template>
                        </ng-template></td>
                  
                        <td>{{order['chargeType']}}</td>
                        <td *ngIf="hidePrice">{{'$'+order['price']}}</td>
                        <td *ngIf="hidePrice">{{'$'+(order['tax'] || '0.00' )}}</td>
                        <td *ngIf="hidePrice">{{order['quantity']}}</td>
                        <td *ngIf="hidePrice">{{'$'+order['total']}}</td>
                        <td>{{order?.completeInfo?.completeStatus == 1 ? '---' : 'Complete'}}</td>
                        <td>{{order?.completedByName || '---'}}</td>
                        <td>{{(order?.completeInfo?.completedAt | date: 'dd MMM, yyyy') ||'---'}}</td>
                        <td class="">
                            <span class="btn w-100" [ngClass]="page === 'opp'? 'green-btn': setStatusStyle(order?.status)"> {{page==='opp'? 'Approved' : (order['status'] === 'Not_Started' ? 'Not Started' : order?.status)}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
         <div *ngIf="(orders && orders.length)" class="w-100">
            <hr class="dashed-line"/>
            <div class="py-3">
                <div class="d-flex justify-content-between align-items-center amount">
                    <h3 class="amount-heading pb-0 mb-2">SubTotal</h3>
                    <span class="amount-heading" >{{'$'+ formatText(details?.totalAmount - taxAmount,2)}}</span>
                </div>
                <div class="d-flex justify-content-between align-items-center amount">
                    <h3 class="amount-breakup pb-0 mb-2">Tax</h3>
                    <span class="amount-breakup">{{'$'+ formatText(totalTaxAmount,2)}}</span>
                </div>
                <div class="d-flex justify-content-between align-items-center amount">
                    <h3 class="amount-breakup pb-0 mb-2">Total</h3>
                    <span class="amount-breakup" >{{'$'+ formatText(details?.totalAmount,2)}}</span>
                </div>
                <div class="d-flex justify-content-between align-items-center amount">
                    <h3 class="amount-breakup pb-0 mb-2">Total Payments</h3>
                    <span class="amount-breakup">{{'$'+ formatText(details?.totalAmountPaid,2)}}</span>
                </div>
                <div class="d-flex justify-content-between align-items-center amount">
                    <h3 class="amount-breakup pb-0 mb-2">Balance Remaining</h3>
                    <span class="amount-breakup">{{'$'+ formatText(details?.totalAmount -details?.totalAmountPaid,2)}}</span>
                </div>
                <div class="d-flex align-items-center justify-content-end amount" >
                    <button class="btn theme-btn" [disabled]="this.details?.totalAmount - (+this.details?.totalAmountPaid)<=0" (click)="openPaymentPopUp()">Pay Now</button>
                </div>
            </div>
        </div>   
        <ng-template #noDataFound>
            <div class="no-data">No Work Order Found.</div>
        </ng-template>
    </div>
</div>
